import { Container, GlobalStyles } from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";
import * as authenticationServive from "../services/authentication";
import CssBaseline from "@mui/material/CssBaseline";
// import * as logger from "./../utils/logger";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation(["common", "Dashboard"]);
  const userData = useSelector((state) => {
    return state.user;
  });

  return (
    <>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0 } }} />
      <CssBaseline />
      <Container maxWidth="sm">
        <h2>{t("Dashboard:dashboard")}</h2>
      </Container>
    </>
  );
};

export default Dashboard;
